<template>
  <!-- 失业保险-->
  <div class="page">
    <calc-page>
      <template slot="topBox">
        <round-container class="rc">
          <div class="item-box">
            <van-cell>
              <div class="row-box">
                <title-item text="累计参保年限"></title-item>
                <year-month-number-input
                  class="date-input"
                  idx="0"
                  ref="begWorkDate"
                  v-model="params.month"
                ></year-month-number-input>
              </div>
            </van-cell>
          </div>
          <!-- <van-divider /> -->

          <!-- <div class="item-box">
            <van-cell is-link>
              <div class="row-box" @click="show = true">
                <title-item text="户口性质"></title-item>
                <span>{{ hkType }}</span>
              </div>
            </van-cell>
          </div> -->
          <van-divider />
        </round-container>
      </template>

      <template slot="footerBtn">
        <div
          v-bind:class="[
            'btn-box',
            'btn-bk'
          ]"
          @click="calc"
        >
          开始计算
        </div>
      </template>
    </calc-page>
    <van-action-sheet
      title="户口性质"
      v-model="show"
      :actions="hkActions"
      @select="onSelect"
    />
  </div>
</template>

<script>
import {
  Cell,
  CellGroup,
  ActionSheet,
  Field,
  Divider,
} from "vant";
import CalcPage from "@/components/CalcPage";
import TitleItem from "@/views/calc/TitleItem";
import YearMonthNumberInput from "@/components/YearMonthNumberInput";
import RoundContainer from "@/views/calc/components/RoundContainer";
import { getUnemployedInsurance } from "@/api/tool";
import to from "@/utils/to";
export default {
  components: {
    CalcPage,
    TitleItem,
    // ataItem,
    YearMonthNumberInput, 
    RoundContainer,
    [Field.name]: Field,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [ActionSheet.name]: ActionSheet,
    [Divider.name]: Divider,
  },
  data() {
    return {
      params: {
        hkType: "",
        month: ""
      },
      show: false,
      hkActions: [
        { name: "城镇", value: "1" },
        { name: "农村", value: "2" },
      ],
      hkType: "",
    };
  },
  methods: {
    onSelect(item) {
      this.hkType = item.name;
      this.params.hkType = item.value;
      this.show = false;
    },
    async calc() {
      if (this.params.month === "") {
        this.$toast.fail("请设置累计参保年限");
        return;
      }
      // if (this.params.hkType === "") {
      //   this.$toast.fail("请选择户口性质");
      //   return;
      // }
      this.$showLoading("计算中...");

      const data = {
        month: this.params.month,
        hkType: this.params.hkType
      }

      let [err, res] = await to(getUnemployedInsurance(data));
      this.$hideLoading();
      if (err) {
        this.$toast.fail("计算失败");
        return;
      }
      this.$router.push({ name: "UnemployResultPage", query: { res: JSON.stringify(res) } });
    },
  },
};
</script>

<style scoped>
.rc {
  min-height: 150px;
}

.page >>> .van-divider {
  margin: 0;
}

.item-box {
  display: flex;
  align-items: flex-end;
  padding: 24px 0 12px 0;
}

.item-box >>> .van-cell {
  padding: 0;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
}

.row-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row-box >>> .TitleItem__title--2DuKR {
  width: 150px;
}

.row-box >>> .van-field__control {
  width: 200px;
  text-align: right;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.row-box span {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.btn-box {
  position: fixed;
  bottom: 10px;
  background: #cccccc;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  color: #ffffff;
  display: flex;
  padding: 10px 130px;
}

.btn-bk {
  background:#658CF1;
}
</style>